import SplitText from '../vendor/gsap/SplitText';

export default class MVPText {

  constructor(el) {

    this.$body = document.body;
    this.$section = el;
    this.background = this.$section.getAttribute('data-background');
    this.tl = this.initTimeline();
    this.tl.add(this.introAnimation(), "a");
    this.tl.add(this.swapColors(), "a");

  }


  // Init MasterTimeline
  initTimeline() {

    return gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "red",
        //   endColor: "red",
        // },
        trigger: this.$section,
        start: () => "top center",
        end: () => "bottom bottom",
        pin: false,
        scrub: true,
      }
    });

  }



  // Animate h1 headline with gsap split text
  introAnimation() {

    const
      $headline = this.$section.querySelector('h1'),
      $lead = this.$section.querySelector('.lead'),
      $link = this.$section.querySelector('.link'),
      headlineTextSplit = new SplitText($headline, {
        type: "lines"
      }),
      tl = gsap.timeline();

    // tl.from(headlineTextSplit.lines, {
    //   duration: 0.6,
    //   opacity: 0,
    //   y: 16,
    //   ease: "power4.out",
    //   stagger: {
    //     amout: 0.3
    //   },
    //   onComplete: () => {
    //     headlineTextSplit.revert()
    //   }
    // });

    tl.from($headline, {
      duration: 0.6,
      opacity: 0,
      y: 32,
      ease: "power4.out"
    });

    tl.from($lead, {
      duration: 0.6,
      opacity: 0,
      y: 32,
      ease: "power4.out"
    });

    tl.from($link, {
      duration: 0.6,
      opacity: 0,
      y: 32,
      ease: "power4.out"
    });

    return tl;

  }



  // Change colors
  swapColors() {

    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "blue",
        //   endColor: "blue",
        // },
        trigger: this.$section,
        start: "top center",
        end: "bottom bottom",
        scrub: 1,
      }
    });

    tl.fromTo('html', {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--hue`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--sat`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-text`),
      "--luma-link": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-link`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-bg`),
    }, {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-hue-default`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-sat-default`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-text-default`),
      "--luma-link": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-link-default`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-bg-default`),
      immediateRender: false,
    })

    return tl;

  }

}
