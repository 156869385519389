// Global
import Cookies from './components/cookies'
import Settings from './components/settings';


// Sections
import LogoSection from './components/logoSection';
import ServicesSection from './components/servicesSection';
import ExperienceSection from './components/experienceSection';
import BallertSection from './components/ballertSection';
import FillerSection from './components/fillerSection';
import MVPText from './components/mvpText';

// Init Cookies Banner
new Cookies();

// Settings
// const $settings = document.querySelector('.settings');
// if ($settings) new Settings($settings);


// Create a scrolltrigger section for each section
// Must be in correct order because of scrubbing arbitrary values
const sections = gsap.utils.toArray("body.home section");
sections.forEach(section => {

  // Switch depending on data-type attribute of the section
  switch (section.getAttribute('data-type')) {

    case "logo":
      new LogoSection(section);
      break;

    case "mvpText":
      new MVPText(section);
      break;

    case "services":
      new ServicesSection(section);
      break;

    case "experience":
      new ExperienceSection(section);
      break;

    case "ballert":
      new BallertSection(section);
      break;

    case "filler":
      new FillerSection(section);
      break;

    default:
      console.warn("No section type found", section);

  }
});


// TESTS
// import SVGPath from './components/svgPath'
// new SVGPath();
