export default class FillerSection {

  constructor(el) {

    this.$body = document.body;
    this.$section = el;
    this.background = this.$section.getAttribute('data-background');
    this.tl = this.initTimeline()
    this.tl.add(this.introAnimation());
    this.tl.add(this.innerAnimations());
    this.tl.add(this.swapColors());

  }


  // Init MasterTimeline
  initTimeline() {

    return gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "gray",
        //   endColor: "gray",
        // },
        trigger: this.$section,
        start: () => "top top",
        end: () => `+=${window.innerHeight * 2}`,
        pin: true,
        pinSpacing: true,
        scrub: 1
      }
    });

  }


  // Section Intro Animation
  introAnimation() {

    const tl = gsap.timeline();

    tl.from(this.$section.querySelector('h1'), {
      opacity: 0,
      y: "-=100",
    });

    tl.eventCallback(
      "onStart",
      function () {
        console.log("Filler start");
        ScrollTrigger.refresh();
        ScrollTrigger.update();
      }
    )

    return tl;

  }


  // Inner Animations
  innerAnimations() {

    const items = gsap.utils.toArray(this.$section.querySelectorAll('li'));
    const tl = gsap.timeline();

    items.forEach(item => {
      let tween = gsap.from(item, {
        opacity: 0,
        x: "-=160",
      })
      tl.add(tween);
    });

    return tl;

  }


  // Change colors
  swapColors() {

    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "gray",
        //   endColor: "gray",
        // },
        trigger: this.$section,
        start: "top center",
        end: "bottom bottom",
        scrub: 1,
      }
    });

    tl.fromTo('html', {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--hue`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--sat`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-text`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-bg`),
    }, {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-hue-default`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-sat-default`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-text-default`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-bg-default`),
      immediateRender: false,
    })

    return tl;

  }

}
