export default class BallertSection {

  constructor(el) {

    this.$body = document.body;
    this.$section = el;
    this.background = this.$section.getAttribute('data-background');

    this.tl = this.initTimeline();
    this.tl.add(this.introTimeline(), "a");
    this.tl.add(this.swapColors(), "a");
    // this.tl.add(this.switchVideos(), "b");
    // this.videosTL = this.switchVideos();

  }


  // Init MasterTimeline

  initTimeline() {

    return gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "black",
        //   endColor: "black",
        // },
        trigger: this.$section,
        start: () => "top top",
        end: () => `+=${window.innerHeight * 2}`,
        pin: true,
        scrub: true,
        snap: true,
        // onEnter: () => {
        //   console.log("Play videos");
        //   this.videosTL.play();
        // },
        // onLeave: () => {
        //   console.log("pause videos");
        //   this.videosTL.pause();
        // },
        // onEnterBack: () => {
        //   console.log("play again videos");
        //   this.videosTL.play();
        // }
      }
    });

  }


  // Animate h1 headline with gsap split text
  introTimeline() {

    const
      $label = this.$section.querySelector('.project__label'),
      $media = this.$section.querySelector('.project__media'),
      tl = gsap.timeline();

    tl.from($label, {
      xPercent: -100,
    });

    tl.from($media, {
      xPercent: 100,
    });

    return tl;

  }



  // Animate through videos: Show each video for 3 seconds

  switchVideos() {

    const
      $videos = this.$section.querySelectorAll('.project__media video'),
      tl = gsap.timeline({
        scrollTrigger: {
          // markers: {
          //   startColor: "blue",
          //   endColor: "blue",
          // },
          trigger: this.$section,
          start: "top top",
          end: () => `+=${window.innerHeight * 3}`,
          scrub: 1,
        }
      });

    $videos.forEach(video => {

      tl.from(video, {
        xPercent: 100,
        autoAlpha: 0
      });

      tl.to(video, {
        delay: 3,
        xPercent: 100,
        autoAlpha: 1
      });

    });

    return tl;
  }



  // Change colors
  swapColors() {

    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "blue",
        //   endColor: "blue",
        // },
        trigger: this.$section,
        start: "top center",
        end: "bottom bottom",
        scrub: 1,
      }
    });

    tl.fromTo('html', {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--hue`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--sat`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-text`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-bg`),
    }, {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--hue-ballert`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--sat-ballert`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-text-ballert`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-bg-ballert`),
      immediateRender: false,
    })

    return tl;

  }

}
