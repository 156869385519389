import drawSVG from '../vendor/gsap/DrawSVGPlugin';

export default class Icons {

  constructor(icon) {

    this.initIcon(icon);

  }


  // Animate icon into existence
  initIcon(icon) {

    // Switch depending on data-type attribute of the section
    switch (icon.getAttribute('id')) {

      case "scrollDown":
        this.scrollDownIcon(icon);
        break;

      case "reveal":
        this.revealIcon(icon);
        break;

      default:
        console.warn("No icon type found", icon);

    }
  }


  // Animate icon out of existence
  hideIcon(icon) {

      // Switch depending on data-type attribute of the section
      switch (icon.getAttribute('id')) {

        case "scrollDown":
          this.scrollDownIcon(icon);
          break;

        case "reveal":
          this.revealIcon(icon);
          break;

        default:
          console.warn("No icon type found", icon);

      }
  }


  // Animate SVG Path
  scrollDownIcon($svg) {

    gsap.registerPlugin(drawSVG);

    // Gsap defaults
    gsap.defaults({
      ease: "none",
    });

    const
      $outline = $svg.querySelector('.outline'),
      $rail = $svg.querySelector('.rail'),
      $train = $svg.querySelector('.train'),
      tlMaster = gsap.timeline(),
      tlRails = gsap.timeline(),
      tlTrain = gsap.timeline({
        repeat: -1,
        delay: 3,
        repeatDelay: 2,
      });

    tlTrain.set($train, {
      drawSVG: "0% 0%",
      autoAlpha: 1,
    })

    tlRails.fromTo($outline, {
      drawSVG: "0%",
    }, {
      duration: 1,
      drawSVG: "100%",
    }, "a");

    tlRails.fromTo($rail, {
      drawSVG: "0%",
    }, {
      duration: 1,
      drawSVG: "100%",
    }, "a");

    tlTrain.fromTo($train, {
      drawSVG: "0% 0%",
    }, {
      duration: 0.15,
      drawSVG: "0% 40%",
    });

    tlTrain.fromTo($train, {
      drawSVG: "0% 40%",
    }, {
      duration: 0.25,
      drawSVG: "60% 100%",
    });

    tlTrain.fromTo($train, {
      drawSVG: "60% 100%",
    }, {
      duration: 0.15,
      drawSVG: "100% 100%",
    });

    tlMaster.add(tlRails, "a");
    tlMaster.add(tlTrain, "b");
  }


  // Animate SVG Path
  revealIcon($svg) {

    gsap.registerPlugin(drawSVG);

    // Gsap defaults
    gsap.defaults({
      ease: "none",
    });

    const
      $outline = $svg.querySelector('.outline'),
      $inner = $svg.querySelector('.inner'),
      $rail = $svg.querySelectorAll('.rail'),
      $train = $svg.querySelectorAll('.train'),
      tlMaster = gsap.timeline(),
      tlRails = gsap.timeline(),
      tlTrain = gsap.timeline({
        repeat: -1,
        delay: 3,
        repeatDelay: 3,
      });

    tlTrain.set($train, {
      drawSVG: "0% 0%",
      autoAlpha: 1,
    })

    tlRails.fromTo([$outline, $inner], {
      drawSVG: "0%",
    }, {
      duration: 1,
      drawSVG: "100%",
    }, "a");

    tlRails.fromTo($rail, {
      drawSVG: "0%",
    }, {
      duration: 1,
      drawSVG: "100%",
    }, "a");

    tlTrain.fromTo($train, {
      drawSVG: "0% 0%",
    }, {
      duration: 0.25,
      drawSVG: "0% 20%",
    });

    tlTrain.fromTo($train, {
      drawSVG: "0% 20%",
    }, {
      duration: 0.5,
      drawSVG: "80% 100%",
    });

    tlTrain.fromTo($train, {
      drawSVG: "80% 100%",
    }, {
      duration: 0.25,
      drawSVG: "100% 100%",
    });

    tlMaster.add(tlRails, "a");
    tlMaster.add(tlTrain, "b");
  }

}
