export default class ExperienceSection {

  constructor(el) {

    this.$body = document.body;
    this.$section = el;
    this.background = this.$section.getAttribute('data-background');

    this.tl = this.initTimeline();
    this.tl.add(this.textTimeline(), "a");
    this.tl.add(this.logosTimeline(), "a");
    this.tl.add(this.swapColors(), "a");

  }


  // Init MasterTimeline

  initTimeline() {

    return gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "yellow",
        //   endColor: "yellow",
        // },
        trigger: this.$section,
        start: () => "top top",
        end: () => `+=${window.innerHeight * 2}`,
        pin: true,
        scrub: true,
        snap: true
      }
    });

  }




  // Animate background and text
  textTimeline() {

    const
      $text = this.$section.querySelector('.experience-section__text'),
      tl = gsap.timeline();

    tl.from($text, {
      scale: 0.8,
      opacity: 0,
    }, "a");

    return tl;

  }




  // Animate h1 headline with gsap split text
  logosTimeline() {

    const
      $logos = this.$section.querySelectorAll('.experience-section__logo'),
      tl = gsap.timeline();

    tl.set($logos, {
      perspective: 1000
    });

    tl.fromTo($logos, {
      z: "-=5000",
      opacity: 0,
    }, {
      z: "1100",
      opacity: 0.3,
      stagger: {
        amount: 1,
        from: "random"
      },
    });

    return tl;

  }



  // Change colors
  swapColors() {

    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "blue",
        //   endColor: "blue",
        // },
        trigger: this.$section,
        start: "top center",
        end: "bottom bottom",
        scrub: 1,
      }
    });

    tl.fromTo('html', {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--hue`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--sat`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-text`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-bg`),
    }, {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-hue-default`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-sat-default`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-text-default`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-bg-default`),
      immediateRender: false,
    })

    return tl;

  }

}
