import SplitText from '../vendor/gsap/SplitText';

export default class ServicesSection {

  constructor(el) {

    this.$body = document.body;
    this.$section = el;
    this.background = this.$section.getAttribute('data-background');
    this.$revealButton = this.$section.querySelector('.reveal-button');
    this.tl = this.initTimeline();
    this.tl.add(this.introAnimation(), "a");
    this.tl.add(this.swapColors(), "a");
    this.revealOnClick();

  }


  // Reveal content on click
  revealOnClick() {

    const $lines = this.$section.querySelectorAll('.line');
    this.$revealButton.addEventListener('click', () => {
      $lines.forEach(line => {
        line.classList.toggle('revealed');
      })
    });

  }


  // Init MasterTimeline
  initTimeline() {

    return gsap.timeline({
      scrollTrigger: {
        markers: {
          startColor: "red",
          endColor: "red",
        },
        trigger: this.$section,
        start: () => "top top",
        end: () => `+=${window.innerHeight * 1}`,
        pin: true,
        // scrub: true,
        // snap: true,
        // snap: {
        //   duration: {min: 0.1, max: 0.1},
        //   delay: 0.1,
        //   ease: "power1.inOut"
        // },
      }
    });

  }



  // Animate h1 headline with gsap split text
  introAnimation() {

    const
      $headline = this.$section.querySelector('.services-section__headline'),
      headlineTextSplit = new SplitText($headline, {
        type: "lines"
      }),
      tl = gsap.timeline();

    let count = 0;
    let tween = gsap.from(headlineTextSplit.lines, {
      duration: 0.6,
      opacity: 0,
      // xPercent: () => {
      //   if (headlineTextSplit.lines.indexOf(line) % 2 == 0) {
      //     return 10;
      //   } else {
      //     return -10;
      //   }
      // },
      xPercent: () => {
        count++;
        if (count % 2 == 0) {
          return 10;
        } else {
          return -10;
        }
      },
      // xPercent: 10,
      ease: "power4.out",
      stagger: {
        amout: 0.3
      }
    })

    tl.add(tween);


    // headlineTextSplit.lines.forEach(line => {
    //   let tween = gsap.from(line, {
    //     duration: 0.6,
    //     opacity: 0,
    //     xPercent: () => {
    //       if (headlineTextSplit.lines.indexOf(line) % 2 == 0) {
    //         return 10;
    //       } else {
    //         return -10;
    //       }
    //     },
    //     ease: "power4.out",
    //   })

    //   tl.add(tween);

    // });

    tl.eventCallback(
      "onStart",
      function () {
        console.log("Services start");
      }
    )

    return tl;

  }



  // Change colors
  swapColors() {

    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: {
        //   startColor: "blue",
        //   endColor: "blue",
        // },
        trigger: this.$section,
        start: "top center",
        end: "bottom bottom",
        scrub: 1,
      }
    });

    tl.fromTo('html', {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--hue`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--sat`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-text`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--luma-bg`),
    }, {
      "--hue": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-hue-default`),
      "--sat": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-sat-default`),
      "--luma-text": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-text-default`),
      "--luma-bg": () => getComputedStyle(document.documentElement).getPropertyValue(`--${this.background}-luma-bg-default`),
      immediateRender: false,
    })

    return tl;

  }

}
